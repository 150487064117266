/** @jsxImportSource @emotion/react */
import React, {useState} from "react";
import {
    useTheme,
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Box,
    Grid,
    Paper,
    Stack,
    Dialog,
    DialogTitle, 
    DialogContent, 
    TextField, 
    DialogActions,
    Snackbar,
    Alert,
} from "@mui/material";
import axiosClient from "../../axios";
import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";
import mockupImg from "./img/mockup.png";
import logo from "./img/logo.png";
import lombardLogo from "./img/lombard.png";
import tradesureLogo from "./img/tradesure.png";
import orcaLogo from "./img/orca.jpg";
import workflow from "./img/workflows.png";

const Root = styled.div`
    flex-grow: 1;
    // background-color: #1f2230;
    // color: #fff;
    font-family: "Outfit", sans-serif;
`;

const appBarStyles = css`
    // background-color: #303748;
    // background-color: #97989e;
    background-color: #778899; //#90acab;
    // padding: 16px;
`;

const toolbarStyles = css`
    display: flex;
    justify-content: space-between;
`;

const buttonStyles = css`
    background-color: #c09630;
    border-radius: 50px;
    color: #fff;
    &:hover {
        background-color: #303748;
    }
`;

const headerStyles = css`
    font-weight: 700;
    margin-top: 50px;
    padding: 32px 0;
`;

const sectionStyles = css`
    padding: 32px 0;
`;

const featureBoxStyles = css`
    padding: 16px;
    background-color: #efeeef;
    color: #000;
`;

const logoStyles = css`
    max-width: 100px;
    margin: 0 10px;
`;

const partnersStyles = css`
    padding: 32px 0;
`;

const Index = () => {
    const [openDemo, setOpenDemo] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("Your demo request is sent successfully! We will get in touch with you.");

    // const receipientEmail = "juan@occusurv.co.za";
    const receipientEmail = "dacubantoane@gmail.com";

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleClickOpenDemo = () => {
        setOpenDemo(true);
    };

    const handleCloseDemo = () => {
        setOpenDemo(false);
    };
    const submitDemoDetails = (event) => {
        event.preventDefault();

        if(email === '' || name === '' || company === '' || jobTitle === '') {
            window.alert("Please fill in all required fields.");
            return;
        }
    
        // Send Demo details to the server
        const response = axiosClient
            .post("/send_public_email", {
                from_email: 'surveys@occusurv.co.za',
                from_name: 'Obsurve Surveys',
                recipient_email: receipientEmail,
                // cc_email: user.email,
                subject: "Obsurve Demo Request",
                body: "Obsurve Demo Request received from the following: <br /><br />" +
                    "Name: " + name + "<br />" +
                    "Email: " + email + "<br />" +
                    "Company: " + company + "<br />" +
                    "Job Title: " + jobTitle + "<br /><br /><br />" +
                    "<i>Email sent from Obsurve website.</i>",
            })
            .then((response) => {
                handleCloseDemo();
                setSnackBarOpen(true);
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };
    return (
        <Root>
            <AppBar position="fixed" css={appBarStyles}>
                <Toolbar css={toolbarStyles}>
                    {/* <Typography variant="h4">Obsurve</Typography> */}
                    <img src={logo} alt="Obsurve Logo" style={{ width: "100px", height: "auto" }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            href="https://www.obsurve.co.za/portal"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginLeft: 16, borderRadius: "50px", borderColor: "#c09630", marginRight: '10px' }}
                        >
                            <Typography variant="body1" sx={{ fontWeight: 'normal', color: '#fff' }}>
                                Login
                            </Typography>
                        </Button>
                        <Button variant="contained" onClick={handleClickOpenDemo} css={buttonStyles}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Get A Demo
                            </Typography>
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Container css={headerStyles}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8}>
                        <Typography 
                            variant="h2" 
                            gutterBottom 
                            sx={{ paddingBottom: '10px', fontWeight: 'bold', letterSpacing: '2px' }}
                        >
                            Streamlined Insurance Risk Requirements Management
                        </Typography>
                        <Typography variant="h5" gutterBottom sx={{ lineHeight: '1.5' }}>
                            Reduce the burden of managing complex insurance risk
                            requirements for your insurer clients. Obsurve's automated
                            web portal streamlines the process, allowing you to
                            efficiently handle multiple client needs on a continuous
                            basis.
                        </Typography>
                        <Box marginTop={5}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleClickOpenDemo}
                                css={buttonStyles}
                            >
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Get A Demo
                                </Typography>
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                href="#learnMoreSection"
                                style={{ marginLeft: 16, borderRadius: "50px", borderColor: "#c09630"}}
                            >
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Learn More
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                        <Grid item xs={12} md={4}>
                            <img
                                src={mockupImg}
                                alt="Portal Mockup"
                                style={{ maxWidth: "100%", width: "400px", height: "auto" }}
                            />
                        </Grid>
                </Grid>
            </Container>
            <Container id="learnMoreSection" css={sectionStyles}>
                <Grid container spacing={3}  alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box display="flex" justifyContent="center" mb={4}>
                            <img
                                src={workflow}
                                alt="Workflow Chart"
                                style={{ maxWidth: "100%" }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" gutterBottom>
                            Simplify the management of insurance risk requirements
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Obsurve enables you to streamline the process of
                            managing insurance risk requirements for your clients.
                            Our platform allows you to easily upload and organise
                            requirements, automate reminders and communication,
                            and track progress and gain insights.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            With Obsurve, you can ensure that all requirements are
                            met in a timely manner, reducing the risk of claims and
                            ensuring that your clients are fully protected.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Paper css={featureBoxStyles}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                                Load & Organise Requirements
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                            Simplify the process of managing your insurance risk requirements by effortlessly uploading or manually entering them directly into 
                            Obsurve. Our user-friendly platform ensures that all your requirements are efficiently organized, readily accessible, and easily 
                            manageable, streamlining your workflow and saving you valuable time.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={featureBoxStyles}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                                Automate Reminders & Communication
                            </Typography>
                            <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                            Keep track of upcoming requirements and ensure that brokers are informed with our automated email reminder feature. 
                            By scheduling reminders, you can eliminate the risk of missing deadlines and improve communication within your team. 
                            Stay on top of your insurance risk management process with Obsurve's efficient automation tools.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper css={featureBoxStyles}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                                Track Progress & Gain Insights
                            </Typography>
                            {/* sx={{ letterSpacing: '0.5px' }} */}
                            <Typography variant="h6" style={{ fontWeight: 'normal' }}>
                            Gain real-time visibility into the progress of your requirements and completion status. You can easily track the status of each 
                            requirement, from initial import to final implementation. Our system provides comprehensive insights, allowing you to identify bottlenecks, 
                            track trends, and make informed decisions to optimize your insurance risk management processes.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Container css={partnersStyles}>
                <Stack spacing={2} alignItems="center">
                    <Box>
                        <Typography variant="h5">
                            Trusted by leading Insurers
                        </Typography>
                    </Box>
                    <Box 
                        sx={{ 
                            width: '100%', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            flexWrap: 'wrap',
                            backgroundColor: '#efeeef', 
                            border: '2px solid #efeeef', 
                            borderRadius: '10px' 

                        }}>
                        <img
                            src={lombardLogo}
                            alt="Lombard"
                            css={logoStyles}
                        />
                        <img
                            src={tradesureLogo}
                            alt="Tradesure"
                            css={logoStyles}
                        />
                        <img
                            src={orcaLogo}
                            alt="Orca"
                            css={logoStyles}
                        />
                    </Box>
                </Stack>
            </Container>
            <Box bgcolor="#778899" color="#FFF" py={4}>
                <Typography variant="body1" align="center">
                    &copy; 2024, Obsurve. All Rights Reserved.
                </Typography>
            </Box>
            {/* // ////////////////////////////////////////////////// DEMO DIALOG /////////////////////////////////////////////////////// */}
            <Dialog open={openDemo} onClose={handleCloseDemo}>
                <DialogTitle><strong>Fill in your details below and we will get in touch with you.</strong></DialogTitle>
                <form onSubmit={submitDemoDetails}>
                    <DialogContent>
                        <TextField autoFocus margin="dense" label="Work Email Address" type="email" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} />
                        <TextField margin="dense" label="Name" type="text" fullWidth required value={name} onChange={(e) => setName(e.target.value)} />
                        <TextField margin="dense" label="Company" type="text" fullWidth required value={company} onChange={(e) => setCompany(e.target.value)} />
                        <TextField margin="dense" label="Job Title" type="text" fullWidth required value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDemo} color="primary">
                            <strong>Cancel</strong>
                        </Button>
                        <Button type="submit" color="secondary">
                            <strong>Submit</strong>
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert
                    onClose={closeSnackbar}
                    severity="success"
                    color="success"
                    sx={{ width: "100%" }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
        </Root>
    );
};

export default Index;
